import useTable from "../../../../MainComponents/Hooks/useTable.jsx";
import HelmetTags from "../../../../MainComponents/HelmetTags.jsx";
import { useState } from "react";

function Students({ type }) {
  const [academicSection, setAcademicSection] = useState("all");

  const url = `${academicSection}/${type === "online" ? "1" : "0"}`;
  const tableFor = type === "online" ? "onlineStudents" : "centerStudents";

  const table = useTable(tableFor, "", "", academicSection, type, url);

  return (
    <section className="h-full w-full overflow-y-auto px-20 py-20  dark:bg-dark dark:text-light sm:px-10">
      <HelmetTags
        title={`${
          type === "online" ? "Online" : "Center"
        } Students | Mrs Amira Shaltout`}
      ></HelmetTags>

      <div className="mb-32 flex flex-col  gap-20">
        <h2 className="fmb-20 text-4xl  font-bold md:text-center">
          {type === "online" ? "Online" : "Center"} Students
        </h2>{" "}
        <div className="flex w-full  gap-10 md:justify-center sm:flex-col-reverse sm:items-center">
          <button
            onClick={() => {
              setAcademicSection("est");
            }}
            className={`min-w-[200px] rounded-xl border-2 border-secondary p-4 font-semibold  transition-all duration-300 hover:bg-light hover:text-secondary   active:scale-90 ${
              academicSection === "est" && "bg-secondary text-light"
            }`}
          >
            EST
          </button>

          <button
            onClick={() => {
              setAcademicSection("act");
            }}
            className={`min-w-[200px] rounded-xl border-2 border-secondary p-4 font-semibold  transition-all duration-300 hover:bg-light hover:text-secondary   active:scale-90 ${
              academicSection === "act" && "bg-secondary text-light"
            }`}
          >
            ACT
          </button>
          <button
            onClick={() => {
              setAcademicSection("dsat");
            }}
            className={`min-w-[200px] rounded-xl border-2 border-secondary p-4 font-semibold  transition-all duration-300 hover:bg-light hover:text-secondary   active:scale-90 ${
              academicSection === "dsat" && "bg-secondary text-light"
            }`}
          >
            DIGITAL SAT
          </button>
        </div>
      </div>
      {/**
       * //!---------Table-----
       */}
      {academicSection !== "all" && (
        <div className="h-fit  w-full">{table}</div>
      )}
    </section>
  );
}

export default Students;
