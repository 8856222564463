import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import useAxios from "../../../../MainComponents/Hooks/useAxios";
import HelmetTags from "../../../../MainComponents/HelmetTags";
import AssistantsInformations from "./AssistantsInformations";
import AssistantsChangePassword from "./AssistantsChangePassword";
import AssistantsChangeImage from "./AssistantsChangeImage";
import AssistatsChangePermissions from "./AssistatsChangePermissions";
import AddNewAssistant from "./AddNewAssistant";

function AddEditAssistant({ edit }) {
  const [values, setValues] = useState(null);
  const [filterActive, setFilterActive] = useState(1);
  const { assistantID } = useParams();

  //!--------- get the assistant info for editing -------

  const [assistantInfo, assistantInfoErrors] = useAxios(
    `${process.env.REACT_APP_ASSISTANT_INFO_API}${assistantID}`,
    "GET",
    assistantID,
    assistantID
  );

  useEffect(() => {
    if (assistantInfo) {
      let temp = assistantInfo.data;
      // delete temp.profile_pic;
      setValues(temp);
    }
  }, [assistantInfo]);

  return (
    <section className="flex h-auto w-full flex-col  items-center  dark:bg-dark dark:text-light">
      <HelmetTags title="The Assistants | Mrs Amira Shaltout"></HelmetTags>

      <div className="form-container my-20 flex w-full flex-col items-center">
        {edit && (
          <>
            <div
              className={`filters flex md:flex-col flex-row-reverse justify-between  items-center w-1/2  relative mb-8 amd-hero:flex-col  amd-hero:w-36  transition-all duration-200 ease-in-out`}
            >
              <button
                onClick={() => setFilterActive(4)}
                className={`z-10 h-full w-1/4 md:w-full py-2 text-2xl font-semibold rounded ${
                  filterActive === 4 &&
                  "rounded-md bg-secondary duration-300 text-light"
                }`}
              >
                Permissions
              </button>
              <button
                onClick={() => setFilterActive(3)}
                className={`z-10 h-full w-1/4 md:w-full py-2 text-2xl font-semibold rounded ${
                  filterActive === 3 &&
                  "rounded-md bg-secondary duration-300 text-light"
                }`}
              >
                Personal picture
              </button>
              <button
                onClick={() => setFilterActive(2)}
                className={`z-10 h-full w-1/4 md:w-full py-2 text-2xl font-semibold rounded ${
                  filterActive === 2 &&
                  "rounded-md bg-secondary duration-300 text-light"
                }`}
              >
                Password
              </button>
              <button
                onClick={() => setFilterActive(1)}
                className={`z-10 h-full w-1/4 md:w-full py-2 text-2xl font-semibold rounded ${
                  filterActive === 1 &&
                  "rounded-md bg-secondary duration-300 text-light"
                }`}
              >
                Information
              </button>
            </div>

            {filterActive === 1 && <AssistantsInformations values={values} />}

            {filterActive === 2 && <AssistantsChangePassword />}

            {filterActive === 3 && (
              <AssistantsChangeImage imgSrc={assistantInfo?.data.profile_pic} />
            )}
            {filterActive === 4 && <AssistatsChangePermissions />}
          </>
        )}

        {!edit && <AddNewAssistant />}
      </div>
    </section>
  );
}

export default AddEditAssistant;
