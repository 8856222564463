import { useEffect, useState } from "react";
import useAxios from "../../../../MainComponents/Hooks/useAxios";
import HelmetTags from "../../../../MainComponents/HelmetTags.jsx";
import { useAuthDispatch } from "../../../../MainComponents/GlobalContext";
import { Loader } from "../../../../MainComponents";
import RequestsCard from "./RequestCard.jsx";

function Requests() {
  const AuthDispatch = useAuthDispatch();

  const [submitDelete, setSubmitDelete] = useState({
    flag: "",
    dependency: false,
    data: {},
  });
  const [submitAccept, setSubmitAccept] = useState({
    flag: "",
    dependency: false,
    data: {},
  });
  const [reFetch, setReFetch] = useState(false);

  //!--------- all Requests request -------

  const [allRequestsData, allRequestsErrors, loading] = useAxios(
    process.env.REACT_APP_ALL_REQUESTS_API,
    "GET",
    "GET",
    reFetch
  );
  //!--------- delete Request -------

  const [deleteRequestSuccess, deleteRequestErrors] = useAxios(
    process.env.REACT_APP_DELETE_REQUEST_API,
    "POST",
    submitDelete.flag,
    submitDelete.dependency,
    submitDelete.data,
    true
  );
  //!--------- accept Request -------

  const [acceptRequestSuccess, acceptRequestErrors] = useAxios(
    process.env.REACT_APP_ACCEPT_REQUEST_API,
    "POST",
    submitAccept.flag,
    submitAccept.dependency,
    submitAccept.data,
    true
  );
  useEffect(() => {
    //! refetch the data on success delete or accept request

    if (deleteRequestSuccess || acceptRequestSuccess) {
      setReFetch(!reFetch);
      AuthDispatch({
        type: "setRefetchIfAcceptOrDeleteRequest",
      });
    }
  }, [deleteRequestSuccess, acceptRequestSuccess]);

  function handleDeleteReq(id) {
    setSubmitDelete({
      flag: "deleteRequest",
      dependency: !submitDelete.dependency,
      data: { id: id },
    });
  }
  function handleAcceptReq(id, level) {
    setSubmitAccept({
      flag: "acceptRequest",
      dependency: !submitAccept.dependency,
      data: { id, level },
    });
  }
  if (loading) {
    return <Loader />;
  }
  return (
    <>
      <HelmetTags title="Requests | Mrs Amira Shaltout"></HelmetTags>

      <section className="h-fit w-full overflow-y-auto px-20 py-20 dark:bg-dark dark:text-light sm:px-10">
        <h2 className="mb-16 w-full  text-4xl font-bold md:text-center">
          Inactive students
        </h2>

        {allRequestsData?.data?.length === 0 ? (
          <p className="w-full text-center">No new requests</p>
        ) : allRequestsErrors ? (
          <p className="w-full text-center">{allRequestsErrors}</p>
        ) : (
          allRequestsData?.data?.map((student, index) => (
            <RequestsCard
              key={index}
              student={student}
              handleAcceptReq={handleAcceptReq}
              handleDeleteReq={handleDeleteReq}
            />
          ))
        )}
      </section>
    </>
  );
}

export default Requests;
